:root {
   --Primary: #2176FF;
   --Secondary: #0861F2;
   --LightTextColor: #050505;
   --LightSecondaryTextColor: #606770;
   --LightBackgroundColor: #F0F2F5;
   --LightContainerColor: #FFFFFF;
   --DarkPrimary: #2176FF;
   --DarkSecondary: #0861F2;
   --DarkTextColor: #E4E6EB;
   --DarkSecondaryTextColor: #B0B3B8;
   --DarkBackgroundColor: #18191A;
   --DarkContainerColor: #242526;
}


html {
/*    min-height: 100%;
   background-color: var(--LightBackgroundColor); */
}

body {
/*    background-color: var(--LightBackgroundColor); */
}

@media (prefers-color-scheme: dark) { 
   html,
   body {
/*       background-color: var(--DarkBackgroundColor);
      color: var(--DarkTextColor); */
   }

   body {
/*       background-color: var(--DarkBackgroundColor); */
   }
}


/*
--bs-primary-bg-subtle{
    background-color: #ed0a8f;
}

-bs-primary-border-subtle{
    background-color: #ed0a8f;
}

--bs-primary-text{
    background-color: #ed0a8f;
}

.btn-primary {
   background-color: #ed0a8f;
   border-color: #f264b7;
}

.btn-primary:hover {
   background-color: #b5006a;
   border-color: #ed0a8f;
}

.btn-primary:focus {
   background-color: #ed0a8f;
   border-color: #f264b7;
}

--bs-primary-border-subtle{
    background-color: #ed0a8f;
    border-color: #f264b7;
}
 */

/* 
:focus {
    outline: none !important;
    box-shadow: none !important;
} */

.btn-secondary {
   background-color: #9616e2;
   border-color: #ba68ea;
}

.btn-secondary:hover {
   background-color: #610498;
   border-color: #9616e2;
}

.btn-secondary:focus {
   background-color: #fff;
   border-color: #f264b7;
}

.myCarousel .carousel-item img {
   height: 100%;
   /* Hace que la imagen ocupe todo el alto disponible */
   object-fit: cover;
   /* Hace que la imagen se ajuste manteniendo la relación de aspecto */
}

.myCarousel .carousel-item {
   height: 600px;
}

.myCarousel .altText {
   font-weight: 900;
}

/* .myCarousel .carousel-caption { 
   background-color: rgba(0, 0, 0, 0.500);
   border-radius: 5px;
} */

@media (min-width: 576px) {

   /* Pantallas pequeñas (sm) */
   .myCarousel .carousel-item {
      height: 350px;
   }
}

@media (min-width: 768px) {

   /* Pantallas medianas (md) */
   .myCarousel .carousel-item {
      height: 400px;
   }
}

@media (min-width: 992px) {

   /* Pantallas grandes (lg) */
   .myCarousel .carousel-item {
      height: 500px;
   }
}

@media (min-width: 1200px) {

   /* Pantallas extra grandes (xl) */
   .myCarousel .carousel-item {
      height: 600px;
   }
}

.myCarousel .carousel-item img {
   height: 100%;
   /* Hace que la imagen ocupe todo el alto disponible */
   width: 100%;
   /* Hace que la imagen ocupe todo el ancho disponible */
   object-fit: cover;
   /* Hace que la imagen se ajuste manteniendo la relación de aspecto */
   /* border-radius: 5px; */
}

.text-flicker-in-glow {
   animation: text-flicker-in-glow 4s linear both;
}

@keyframes text-flicker-in-glow {
   0% {
      opacity: 0;
   }

   10% {
      opacity: 0;
      text-shadow: none;
   }

   10.1% {
      opacity: 1;
      text-shadow: none;
   }

   10.2% {
      opacity: 0;
      text-shadow: none;
   }

   20% {
      opacity: 0;
      text-shadow: none;
   }

   20.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
   }

   20.6% {
      opacity: 0;
      text-shadow: none;
   }

   30% {
      opacity: 0;
      text-shadow: none;
   }

   30.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
   }

   30.5% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
   }

   30.6% {
      opacity: 0;
      text-shadow: none;
   }

   45% {
      opacity: 0;
      text-shadow: none;
   }

   45.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
   }

   50% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
   }

   55% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
   }

   55.1% {
      opacity: 0;
      text-shadow: none;
   }

   57% {
      opacity: 0;
      text-shadow: none;
   }

   57.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
   }

   60% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
   }

   60.1% {
      opacity: 0;
      text-shadow: none;
   }

   65% {
      opacity: 0;
      text-shadow: none;
   }

   65.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
   }

   75% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
   }

   75.1% {
      opacity: 0;
      text-shadow: none;
   }

   77% {
      opacity: 0;
      text-shadow: none;
   }

   77.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
   }

   85% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
   }

   85.1% {
      opacity: 0;
      text-shadow: none;
   }

   86% {
      opacity: 0;
      text-shadow: none;
   }

   86.1% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
   }

   100% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
   }
}